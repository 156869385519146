import c0 from './clipboard_controller';
import c1 from './dismissable_alert_controller';
import c2 from './form_submit_controller';
import c3 from './load_recaptcha_v2_controller';
import c4 from './toggle_controller';
export const definitions = [
	{identifier: 'clipboard', controllerConstructor: c0},
	{identifier: 'dismissable-alert', controllerConstructor: c1},
	{identifier: 'form-submit', controllerConstructor: c2},
	{identifier: 'load-recaptcha-v2', controllerConstructor: c3},
	{identifier: 'toggle', controllerConstructor: c4},
];
