import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"]
  static classes = ["visibility"]

  toggle(event) {
    event.preventDefault();
    this.contentTargets.forEach(t => t.classList.toggle(this.visibilityClass))
  }
}
