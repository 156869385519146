import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  remove(e) {
    e.preventDefault()
    this.element.classList.add('animate-up-and-out', 'max-h-0')

    // remove from DOM after animation completes
    setTimeout(() => {
      this.element.parentNode.removeChild(this.element);
    }, 2000)
  }
}
