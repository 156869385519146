import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "source", "notificationContainer" ]
  static values = {
    notificationText: {type: String, default: 'Link copied to clipboard!'}
  }

  copy(event) {
    event.preventDefault()
    const controller = this

    navigator.clipboard.writeText(this.textToCopy).then(() => {
      controller.handleNotification()
    }, () => {
      console.error("Clipboard copy failed")
    })
  }

  // private

  handleNotification() {
    if (this.hasNotificationContainerTarget) {
      this.notificationContainerTarget.classList.remove("hidden");
      this.notificationContainerTarget.innerText = this.notificationTextValue;
      setTimeout(() => {
        this.notificationContainerTarget.classList.add("hidden");;
      }, 1500)
    }
  }

  get textToCopy() {
    return this.sourceTarget.value
  }
}
